import {useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {css, useTheme} from "@emotion/react";
import styled from "@emotion/styled";
import {Button} from "@pg-design/button";
import {Col, Container, Row} from "@pg-design/grid";
import {flex, flexDirection, mb, mt, onDesktop, pb, pt, textAlign, w100} from "@pg-design/helpers-css";
import {Picture} from "@pg-design/picture";
import {getTextVariantStyleFor, Text} from "@pg-design/text";

import {useAppDispatch} from "../../../../../utils/hooks/store_hooks";
import {setResponseStatus} from "../../app_status/app_status_slice";
import {ResponseStatus} from "../../ssr/path_to_action/state/app_status/IAppStatusState";
import {useGetContentQuery} from "../../static_content/api/get_content";
import {StaticContent} from "../../static_content/types/Content";

import analystImageSmall from "../../../common/app/assets/pg_employees/marek_wielgo_80x80.jpg";
import analystImage from "../../../common/app/assets/pg_employees/marek_wielgo_200x200.jpg";

export const AnalystComment = () => {
    const {t, i18n} = useTranslation();
    const theme = useTheme();
    const [expanded, setExpanded] = useState(false);
    const {data, isError} = useGetContentQuery(StaticContent.HOMEPAGE_COMMENT);
    const dispatch = useAppDispatch();

    if (isError) {
        dispatch(setResponseStatus({responseStatus: ResponseStatus.PAGE_500}));
        return null;
    }
    return (
        <Container css={[pt(9), pb(12)]} as="section">
            <Row>
                <Col lg={3} lgOffset={2} md={3} mdOffset={1} sm={4}>
                    <div css={expert}>
                        <Picture
                            css={image}
                            sources={[
                                {minWidthPX: 0, src: analystImageSmall, height: 80, width: 80},
                                {minWidthPX: 1024, src: analystImage, height: 200, width: 200}
                            ]}
                            alt="Marek Wielgo"
                            loading="lazy"
                        />

                        <div css={onDesktop(mt(4))}>
                            <Text css={[mb(2), onDesktop(textAlign("center"))]} as="p" variant="headline_3">
                                Marek Wielgo
                            </Text>

                            <Text
                                css={onDesktop(textAlign("center"))}
                                color={theme.colors.gray[700]}
                                variant="body_copy_1"
                            >
                                <Trans>{t("landing.analyst_comment.expert_role")}</Trans>
                            </Text>
                        </div>
                    </div>
                </Col>

                <Col lg={9} md={7} sm={4}>
                    {data && data.content_pl && data.content_en && (
                        <CommentWrapper
                            expanded={expanded}
                            dangerouslySetInnerHTML={{
                                __html: i18n.language === "pl" ? data.content_pl : data.content_en
                            }}
                        />
                    )}

                    <Button css={button} variant="outlined_secondary" onClick={() => setExpanded(!expanded)}>
                        {!expanded
                            ? t("landing.analyst_comment.btn_expand")
                            : t("landing.analyst_comment.btn_collapse")}
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

const expert = css`
    ${flex("center", "space-between")};

    ${onDesktop(css`
        justify-content: initial;
        ${flexDirection("column")}
    `)}
`;

const image = css`
    border-radius: 50%;
    overflow: hidden;
`;

const headline = css`
    ${pt(4)};

    ${onDesktop(css`
        padding-top: initial;
    `)}
`;

const CommentWrapper = styled.div<{expanded: boolean}>`
    p:first-of-type {
        ${headline};
        ${(props) => getTextVariantStyleFor("headline_2")(props.theme)};
    }

    p:nth-of-type(2) {
        ${mt(2)};
        ${(props) => getTextVariantStyleFor("headline_4")(props.theme)};
    }

    p {
        ${mt(4)};
        ${(props) => getTextVariantStyleFor("body_copy_0")(props.theme)};
    }

    p:nth-last-of-type(-n + 3) {
        ${(props) => !props.expanded && "display:none"};
    }
`;

const button = css`
    ${mt(4)};
    ${w100};

    ${onDesktop(css`
        width: initial;
        flex-basis: initial;
    `)}
`;
