import {useTranslation} from "react-i18next";
import {css, Theme} from "@emotion/react";
import {Col} from "@pg-design/grid";
import {calculateRemSize, flexJustifyCenter, mb, pb, pt, w100} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

import {IPGEmployee} from "../../common/app/constants/pg_employees";
import {mediaBox} from "../views/Contact";
import {ContactPerson} from "./ContactPerson";

interface IContactListProps {
    title?: string;
    className?: string;
    isMonitoring?: boolean;
    persons: IPGEmployee[];
}

export const ContactList = ({title, isMonitoring, className, persons}: IContactListProps) => {
    const {t} = useTranslation();

    return (
        <Col css={wrap} noGutters className={className}>
            {title && (
                <Text variant="headline_1" align="center" css={[mb(6), w100]} as="h3">
                    {title}
                </Text>
            )}

            {persons.map((person, i) => (
                <Col sm={4} md={isMonitoring ? 12 : 6} lg={isMonitoring ? 16 : 8} key={i} css={contactPersonWrapper}>
                    <ContactPerson
                        css={mediaBox}
                        email={person.email}
                        picture={person.picture}
                        name={person.name}
                        phone={person.phone}
                        role={t(person.role)}
                    />
                </Col>
            ))}
        </Col>
    );
};

const wrap = css`
    position: relative;
    flex-wrap: wrap;
    row-gap: ${calculateRemSize(3)};
    ${flexJustifyCenter}
`;

const contactPersonWrapper = (theme: Theme) => css`
    @media (max-width: ${theme.breakpoints.md}) {
        &:first-of-type > div {
            ${pt(5)};
        }

        &:last-of-type > div {
            ${pb(3)};
        }

        &:not(:first-of-type) > div > p {
            ${mb(0)};
        }
    }
`;
