import {backendApiPath, chartsApiPath} from "./api_path";

// private scenarios are those that require user validation via `checkDataAccessCredentials`
export const privateScenarios = ["prices", "offer", "demand", "sold", "added"];

export const rpScenarios = ["rp-price", "rp-price-active", "rp-price-avg", "rp-price-avg-rooms", "rp-avg"];

// public scenarios are those that do not require user validation
export const publicScenarios = ["dashboard", "home", ...rpScenarios];

// all scenarios
export const scenarios: string[] = [...privateScenarios, ...publicScenarios];

export const pathsWithoutScenario = [chartsApiPath.currentDate, backendApiPath.cookies, chartsApiPath.newsletter];
