import {useTranslation} from "react-i18next";
import {css, useTheme} from "@emotion/react";
import {Button} from "@pg-design/button";
import {Col, Container, Row} from "@pg-design/grid";
import {calculateRemSize, flexAlignCenter, mb, mt, mv, pb, pr} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

import {pgEmployees} from "../../../common/app/constants/pg_employees";
import {Divider} from "../../../components/Divider";
import {Heading} from "../../../components/Heading";
import {SimpleContainer} from "../../../components/layout/SimpleContainer";
import {CustomerSupport} from "../../monitoring/components/CustomerSupport";
import {pageviewAlgolyticsHit} from "../../tracking/algolytics/pageview_hits";
import {usePageview} from "../../tracking/use_pageview";
import {pageviewUserComHit} from "../../tracking/user_com/hit_user_com";
import {ViewType} from "../../tracking/view_type";
import {Price} from "../components/Price";
import {PricingBox} from "../components/PricingBox";

export const Pricing = () => {
    usePageview(() => {
        pageviewAlgolyticsHit({viewType: ViewType.PRICING});
        pageviewUserComHit();
    });
    const {t} = useTranslation();
    const theme = useTheme();

    const forDeveloperButton = (
        <span css={forDeveloperButtonHolder}>
            <Button
                variant="filled_primary"
                size="x-small"
                href="https://rynekpierwotny.pl/dla-dewelopera/"
                target="_blank"
            >
                {t("pricing.rp_button")}
            </Button>
        </span>
    );

    const popularCitiesStr = `${t("city.wroclaw.nominative")}, ${t("city.poznan.nominative")}, ${t("city.gdansk.nominative")}, ${t("city.lodz.nominative")}, ${t("city.krakow.nominative")}, ${t("city.katowice.nominative")} + ${t("pricing.agglomerations")}`;

    return (
        <SimpleContainer>
            <Container as="section">
                <Heading text={t("pricing.title")} />

                <Text variant="headline_5" as="p" align="center" css={pb(4)}>
                    {t("pricing.subtitle")}
                </Text>

                <Row css={[wrapper, pb(6)]}>
                    <Col sm={4} md={4}>
                        <PricingBox
                            header={t("pricing.all_cities")}
                            subheader={t("pricing.service_customers")}
                            additionalContent={forDeveloperButton}
                        >
                            <Text variant="headline_3">{t("pricing.free_access")}</Text>
                        </PricingBox>
                    </Col>

                    <Col sm={4} md={4}>
                        <PricingBox header={t("pricing.all_cities")} subheader={t("pricing.other_customers")}>
                            <div css={[mt(2)]}>
                                <Price price="1649" />
                                <Text variant="body_copy_2" color={theme.colors.gray[800]}>
                                    {t("pricing.price_info_with_annual_subscription")}
                                </Text>
                            </div>
                            <Divider css={[mv(2)]} />
                            <div css={[mb(2)]}>
                                <Price price="2000" />
                                <Text variant="body_copy_2" color={theme.colors.gray[800]}>
                                    {t("pricing.price_info_without_annual_subscription")}
                                </Text>
                            </div>
                        </PricingBox>
                    </Col>

                    <Col sm={4} md={4}>
                        <PricingBox header={t("pricing.single_city")} subheader={t("pricing.other_customers")}>
                            <div css={[mv(2)]}>
                                <div css={priceRow}>
                                    <Text variant="body_copy_2">
                                        {t("city.warszawa.nominative")} + {t("pricing.agglomeration")}
                                    </Text>
                                    <Price price="799" />
                                </div>

                                <div css={priceRow}>
                                    <Text css={[citiesRow, pr(1)]} variant="body_copy_2">
                                        {popularCitiesStr}
                                    </Text>
                                    <Price price="599" />
                                </div>

                                <div css={[priceRow, mb(0.5)]}>
                                    <Text variant="body_copy_2">{t("pricing.other_cities")}</Text>
                                    <Price price="299" />
                                </div>

                                <Text variant="body_copy_2" color={theme.colors.gray[800]}>
                                    {t("pricing.price_info_with_annual_subscription")}
                                </Text>
                            </div>

                            <Divider css={[mv(2)]} />

                            <div css={priceRow}>
                                <Text variant="body_copy_2">
                                    {t("city.warszawa.nominative")} + {t("pricing.agglomeration")}
                                </Text>
                                <Price price="950" />
                            </div>

                            <div css={priceRow}>
                                <Text css={[citiesRow, pr(1)]} variant="body_copy_2">
                                    {popularCitiesStr}
                                </Text>
                                <Price price="720" />
                            </div>

                            <div css={[priceRow, mb(0.5)]}>
                                <Text variant="body_copy_2">{t("pricing.other_cities")}</Text>
                                <Price price="360" />
                            </div>

                            <Text variant="body_copy_2" color={theme.colors.gray[800]} css={[mb(2)]}>
                                {t("pricing.price_info_without_annual_subscription")}
                            </Text>
                        </PricingBox>
                    </Col>
                </Row>

                <Text variant="headline_5" as="p" align="center" css={mb(4)}>
                    {t("pricing.monitoring")}
                </Text>

                <Row css={wrapper}>
                    <Col sm={4} md={6}>
                        <PricingBox
                            header={t("monitoring.hero.title")}
                            subheader={t("pricing.service_customers")}
                            additionalContent={forDeveloperButton}
                        >
                            <Price price="1900" />
                        </PricingBox>
                    </Col>

                    <Col sm={4} md={6}>
                        <PricingBox header={t("monitoring.hero.title")} subheader={t("pricing.other_customers")}>
                            <Price price="2900" />
                        </PricingBox>
                    </Col>
                </Row>

                <CustomerSupport title={t("contact.title")} persons={[pgEmployees.aleksandra_boratynska]} />
            </Container>
        </SimpleContainer>
    );
};

const wrapper = css`
    row-gap: ${calculateRemSize(2)};
`;

const priceRow = css`
    ${flexAlignCenter};
    justify-content: space-between;
`;

const citiesRow = css`
    flex: 1;
`;

const forDeveloperButtonHolder = css`
    margin-top: 1rem;
    display: block;
`;
