import {Col, Container, Row} from "@pg-design/grid";
import {mb, pv} from "@pg-design/helpers-css";

import {IPGEmployee} from "../../../common/app/constants/pg_employees";
import {ContactList} from "../../../components/contact/ContactList";
import {RodoTerms} from "../../../components/RodoTerms";

interface ICustomerSupportProps {
    title?: string;
    isMonitoring?: boolean;
    persons: IPGEmployee[];
}

export const CustomerSupport = ({title, isMonitoring, persons}: ICustomerSupportProps) => {
    return (
        <Container as="section" fluid css={pv(9)}>
            <Container>
                <Row>
                    <Col sm={4} md={12} lg={12} lgOffset={2}>
                        <ContactList title={title} isMonitoring={isMonitoring} css={mb(2)} persons={persons} />
                        <RodoTerms />
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};
