import {css} from "@emotion/react";
import {mb, pb} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image";
import {Text} from "@pg-design/text";

import {IPGEmployee} from "../../common/app/constants/pg_employees";

interface IContactPersonProps extends IPGEmployee {
    className?: string;
}

export const ContactPerson = ({picture, name, role, phone, email, className}: IContactPersonProps) => {
    return (
        <div css={className}>
            <Image src={picture} alt={name} width="160" height="160" css={[personImg, mb(4)]} loading="lazy" />

            <div css={pb(3)}>
                <Text variant="body_copy_1" align="center">
                    {name}
                </Text>

                <Text variant="info_txt_1" align="center">
                    {role}
                </Text>
            </div>

            <div>
                <Text variant="body_copy_1" strong align="center">
                    <a href={`tel:${phone}`}>{phone}</a>
                </Text>
                <Text variant="body_copy_2" align="center">
                    <a href={`mailto:${email}`}>{email}</a>
                </Text>
            </div>
        </div>
    );
};

const personImg = css`
    border-radius: 50%;
`;
