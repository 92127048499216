import aleksandra_boratynska from "../assets/pg_employees/aleksandra_boratynska_160x160.jpg";
import dorota from "../assets/pg_employees/dorota_wachnicka_160x160.jpg";
import lukasz_dominiak from "../assets/pg_employees/lukasz_dominiak_160x160.png";
import mikolaj_ostrowski from "../assets/pg_employees/mikolaj_ostrowski_160x160.png";

export interface IPGEmployee {
    name: string;
    role: string;
    phone: string;
    email: string;
    picture: string;
}

export const pgEmployees = {
    mikolaj_ostrowski: {
        name: "Mikołaj Ostrowski",
        role: "common.pr_manager",
        phone: "+48 530 619 988",
        email: "pr@rynekpierwotny.pl",
        picture: mikolaj_ostrowski
    },
    aleksandra_boratynska: {
        name: "Aleksandra Boratyńska",
        role: "common.monitoring",
        phone: "+48 532 758 886",
        email: "a.boratynska@rynekpierwotny.pl",
        picture: aleksandra_boratynska
    },
    lukasz_dominiak: {
        name: "Łukasz Dominiak",
        role: "common.bd_access",
        phone: "+48 731 733 370",
        email: "l.dominiak@rynekpierwotny.pl",
        picture: lukasz_dominiak
    },
    dorota_wachnicka: {
        name: "Dorota Wachnicka",
        role: "common.customer_service_manager",
        phone: "+48 784 930 517",
        email: "d.wachnicka@rynekpierwotny.pl",
        picture: dorota
    }
};
