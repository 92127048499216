import {useTranslation} from "react-i18next";
import {mb} from "@pg-design/helpers-css";
import {Modal} from "@pg-design/modal";
import {Text} from "@pg-design/text";

import {IPGEmployee} from "../../../../../common/app/constants/pg_employees";
import {RodoTerms} from "../../../../../components/RodoTerms";
import {ContactPerson} from "./ContactPerson";

interface IProps {
    title: string;
    message?: string;
    persons: IPGEmployee[];
}

export const ContactMessage = (props: IProps) => {
    const {title, message, persons} = props;
    const {t} = useTranslation();

    return (
        <>
            <Modal.Header>
                <Text css={mb(3)} variant="headline_6" as="span" align="center">
                    {title}
                </Text>
            </Modal.Header>

            <Modal.Content>
                {message && (
                    <Text variant="body_copy_1" align="center" css={mb(3)}>
                        {message}
                    </Text>
                )}

                {persons.map((person) => (
                    <ContactPerson
                        key={person.email}
                        css={mb(3)}
                        picture={person.picture}
                        name={person.name}
                        role={t(person.role)}
                        phone={person.phone}
                        email={person.email}
                    />
                ))}

                <RodoTerms />
            </Modal.Content>
        </>
    );
};
