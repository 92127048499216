import {Trans} from "react-i18next";
import {css, Theme, useTheme} from "@emotion/react";
import {calculateRemSize, flex, flexAlignCenter, mb, onDesktop} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image";
import {Text} from "@pg-design/text";

import {stringRemoveAllOccurrences} from "../../../../../../../utils/misc";
import {IPGEmployee} from "../../../../../common/app/constants/pg_employees";

interface IContactPersonProps extends IPGEmployee {
    className?: string;
}

export const ContactPerson = (props: IContactPersonProps) => {
    const {className, picture, name, role, phone, email} = props;
    const theme = useTheme();

    return (
        <div css={contactInfo} className={className}>
            <div css={imgWrapper}>
                <Image src={picture} css={personImg} alt={picture} width="160" height="160" loading="lazy" />
            </div>

            <div css={contactText}>
                <Text variant="body_copy_1" align="center" css={mb(0.5)}>
                    {name}
                </Text>

                <Text variant="info_txt_1" align="center" css={[mb(3), roleText]} color={theme.colors.gray[800]}>
                    <Trans>{role}</Trans>
                </Text>

                <Text variant="body_copy_1" align="center" css={mb(0.5)} strong>
                    <a href={`tel:${stringRemoveAllOccurrences(phone, " ")}`}>{phone}</a>
                </Text>

                <Text variant="body_copy_2" align="center">
                    <a href={email}>{email}</a>
                </Text>
            </div>
        </div>
    );
};

const contactInfo = css`
    ${flexAlignCenter};
    flex-direction: column;
    column-gap: ${calculateRemSize(6)};

    ${onDesktop(css`
        flex-direction: row;
    `)};
`;

const roleText = css`
    max-width: 250px;
`;

const imgWrapper = (theme: Theme) => css`
    ${flex("normal", "end")};
    flex: 1;

    @media (max-width: ${theme.breakpoints.md}) {
        ${mb(6)}
    }
`;

const personImg = css`
    border-radius: 50%;
`;

const contactText = css`
    flex: 1;

    ${onDesktop(css`
        & > p {
            text-align: left;
        }
    `)};
`;
